(function ($) {
  // check if overlay link should display when L2 inventory data is received
  $(document).on('inventory_status_stored', function () {
    $('.promo-waitlist-overlay').each(function () {
      var sku_id = $(this).attr('data-skuid');
      var sku = $(document).data(sku_id);

      // check availability and set offer id. 1 = in stock, 6 = available promo stock
      if (sku && ('1,6'.indexOf(sku.INVENTORY_STATUS) === -1)) {
        $links = $('.waitlist-link-' + sku_id);
        $hiddenLinks = $('.hidden.waitlist-link-' + sku_id);
        // if we have some hidden and some visible links, assume the intention is to swap them when sku is OOS
        var useToggle = $hiddenLinks.length && $links.length > $hiddenLinks.length;

        $links.each(function () {
          // handle click events on any of the waitlist links... class="waitlist-link-SKU12345"
          if (useToggle) {
            $(this).toggleClass('hidden');
          } else {
            $(this).removeClass('hidden');
          }
          $(this).on('click', function(e) {
            e.preventDefault();
            var data = {
              sku_id: sku.SKU_BASE_ID
            };

            Drupal.cl.template.get({
              path: 'promo_waitlist_overlay',
              object: data,
              callback: function (html) {
                var wrapperClass = 'waitlist-overlay';

                generic.overlay.launch({
                  // wrap the content so we already know our toplevel selector
                  content: '<div class="' + wrapperClass + '">' + html + '</div>',
                  includeBackground: false,
                  includeCloseLink: true,
                  hideOnClickOutside: true,
                  cssStyle: {
                    border: '1px solid #5dba98',
                    width: '346px',
                    height: '220px',
                    padding: '0'
                  }
                });
                var formArgs = {
                  container: $('.' + wrapperClass),
                  params: {}
                };

                site.waitlist.initForm(formArgs);
              }
            });
          });
        });
      }
    });
  });
})(jQuery);
